
















import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    value: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      loaded: false,
      items: [],
    });
    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/session-topic`)
        .then(({ data: { sessionTopic } }) => {
          state.items = sessionTopic.filter((el: any) => el.isActive);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };
    return { state, rules };
  },
});
